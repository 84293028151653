import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Section from "../components/Section";
import { Grid } from "semantic-ui-react";
import { Company } from "./100";
import { 
  HeaderH1, 
  Letter, 
  LetterLogo,
  LogoHiddenLabel,
  ResponsiveContainer, 
  ResponsiveGrid,
  SectionH2,
  SectionP,
  PartnerLogo,
  SectionH3
} from "../helpers/styledComponents";
import blacklogo from '../../static/mediaLogos/civic-alliance-logo-black.png';


const HeaderContent = (
  <ResponsiveContainer tighten="true">
    <HeaderH1  style={{ maxWidth: "700px", margin: "auto", paddingBottom: "20px", marginBottom: ".5em" }}>
      Joint Statement on Protecting Voting Access
    </HeaderH1>
  </ResponsiveContainer>
)


export default ({ data }) => {
  return (
    <Layout 
      mode="dark" 
      headerContent={HeaderContent} 
      // caaBannerContent={RedBannerContent}
      title="Protecting Voting Access | Civic Alliance"
    >
      <Section mode="light" extraMargin>
        <Letter>
          <LetterLogo src={blacklogo} style={{ margin: "0 auto 2em" }}/>
          <SectionP right>April 2, 2021</SectionP>
          <SectionH3>The Right to Vote is the Cornerstone of our Democracy.</SectionH3>
          <SectionP>We believe every American should have a voice in our democracy and that voting should be safe and accessible to all voters. We stand in solidarity with voters 一 and with the Black executives and leaders at the helm of this movement 一 in our nonpartisan commitment to equality and democracy. If our government is going to work for all of us, each of us must have equal freedom to vote and elections must reflect the will of voters.</SectionP>
          <SectionP>Our elections are not improved when lawmakers impose barriers that result in longer lines at the polls or that reduce access to secure ballot dropboxes. There are hundreds of bills threatening to make voting more difficult in dozens of states nationwide. We call on elected leaders in every state capitol and in Congress to work across the aisle and ensure that every eligible American has the freedom to easily cast their ballot and participate fully in our democracy.</SectionP>

          <SectionH2>Signatories</SectionH2>
          <ResponsiveGrid centered negativeMargin>
            { data.allContentfulCivicAllianceCompany.nodes
                .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
                .map(company => 
                  <Company 
                    key={company.id}
                    {...company}
                  />
                )
            }
          </ResponsiveGrid>
        </Letter>
      </Section>
    </Layout>
  );
};


export const query = graphql`
  query {
    allContentfulCivicAllianceCompany(filter: {commitments: {in: "Protecting Voting Access"}, logo: {file: {fileName: {ne: null}}}}, sort: {fields: name}) {
      nodes {
        name
        id
        commitments
        commitmentLevel
        executiveName
        executiveTitle
        logoSize
        logo {
          file {
            fileName
            url
          }
          fluid(maxWidth: 140) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;