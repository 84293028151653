import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Grid } from "semantic-ui-react";
import Section from "../components/Section";
import Layout from "../components/Layout";
import { ButtonLink, ButtonAnchor } from "../components/Buttons";
import { 
  ExecutiveName,
  ExecutiveTitle,
  HeaderH1,
  Letter,
  LogoHiddenLabel,
  ResponsiveGrid,
  SectionH1,
  SectionH2,
  SectionH4,
  SectionP,
  PartnerLogo
} from "../helpers/styledComponents";
import { knuthShuffle, getRandomInt } from "../helpers/helperfns";
import { iCommitmentsSectionProps, iCompanyProps } from "../helpers/types";
import styled from "styled-components";

const headerContent = (
  <HeaderH1 style={{ paddingBottom: "20px", marginBottom: ".5em" }}>
    We're <b style={{fontSize: "1.6em"}}>100% In</b> for democracy
  </HeaderH1>
);

const StyledOL = styled.ol`
  font-size: 1.25em;
  text-align: left;
  list-style: none;
  list-style-type: none;
  padding-left: 80px;
  margin: 0;

  li {
    position: relative;
    margin-bottom: 30px;

    span {
      margin-top: -4px !important;
      left: -60px; 
      position: absolute;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 1em !important;
    }
  }
`;

const StyledImageWrapper = styled.div`
  .gatsby-image-wrapper {
    div {
      padding-bottom: 0 !important;
    }

    picture {
      img {
        position: relative !important;
        top: auto !important;
        left: auto !important;
        width: 100% !important;
        object-fit: contain!important;
        max-height: 40px !important;
      }
    }
  }
  image: {
    width: auto;
  }
`;

export const Company:React.FC<iCompanyProps> = ({ name, executiveName, executiveTitle, logo }) => {
  return (
    <Grid.Column title={name} textAlign="center" verticalAlign="middle" style={{ padding: "2rem .75rem" }}>
      <StyledImageWrapper>
        <Img fluid={logo.fluid} style={{ maxHeight: "40px", width: "auto" }} />
      </StyledImageWrapper>
      <ExecutiveName>{executiveName}</ExecutiveName>
      <ExecutiveTitle>{executiveTitle}</ExecutiveTitle>
      <LogoHiddenLabel>{name}</LogoHiddenLabel>
    </Grid.Column>
  );
}

const CommitmentsSection:React.FC<iCommitmentsSectionProps> = ({companies}) => {
  let companiesA = 
    knuthShuffle(
      companies.filter(company => company.commitments.includes("100% In For Democracy") && company.commitmentLevel === 'A')
    ).map(company => <Company key={company.id} {...company}/>);

  let companiesB = 
    knuthShuffle(
      companies.filter(company => company.commitments.includes("100% In For Democracy") && company.commitmentLevel === 'B')
    ).map(company => <Company key={company.id} {...company}/>);

  let companiesC = 
    knuthShuffle(
      companies.filter(company => 
        company.commitments.includes("100% In For Democracy") 
        && company.commitmentLevel === 'C' 
        && !['Solve', 'Neu'].includes(company.name)
      )
    ).map(company => <Company key={company.id} {...company}/>);

  let Neu = companies.find(company => company.name === "Neu");
  let Solve = companies.find(company => company.name === "Solve");

  let randomIndex = getRandomInt(companiesC.length);

  companiesC.splice(
    randomIndex, 
    0,
    (<Grid.Column key={Neu?.id} title="Solve \& Neu" textAlign="center" verticalAlign="middle" style={{ padding: "2rem .75rem" }}>
      <StyledImageWrapper>
        <Img fluid={Neu?.logo.fluid} style={{ maxHeight: "40px", width: "auto" }} />
      </StyledImageWrapper>
      <StyledImageWrapper>
        <Img fluid={Solve?.logo.fluid} style={{ maxHeight: "40px", width: "auto" }} />
      </StyledImageWrapper>
      <ExecutiveName>{Neu?.executiveName}</ExecutiveName>
      <ExecutiveTitle>{Neu?.executiveTitle}</ExecutiveTitle>
      <LogoHiddenLabel>Solve & Neu</LogoHiddenLabel>
    </Grid.Column>)
  )

  return <>{[...companiesA, ...companiesB, ...companiesC]}</>;
}


export default ({ data }) => {
  return (
    
    <Layout mode="dark" headerContent={headerContent} title="We're 100% in for Democracy | Civic Alliance">
      <Section mode="light" relaxedPadding>
        <Letter center>

          <SectionP>Each of our member companies is unique. Yet, we are united by these nonpartisan values: Every American has a voice in our democracy. Voting should be safe and accessible to all. Elections should be fair and transparent.</SectionP>
          
          <SectionH4 style={{paddingBottom: "20px" }}><u>We, the undersigned:</u></SectionH4>
          <div style={{ maxWidth: "95%", margin: "auto" }}>
            <StyledOL>
              <li>
                <span className="ui circular label black">1</span>
                <SectionP><b>Call for safe access to the polls for all voters.</b></SectionP>
              </li>
              <li>
                <span className="ui circular label black">2</span>
                <SectionP><b>Recognize state and local election officials as the trusted source for certified results.</b></SectionP>
              </li>
              <li>
              <span className="ui circular label black">3</span>
                <SectionP><b>Encourage patience as officials count every vote.</b></SectionP>
              </li>
            </StyledOL>
          </div>
          <SectionP>America’s democracy is strong. Our strength lies in our people – each with the power to shape our country’s future.</SectionP>
          <SectionP>We celebrate the record number of Americans who are voting in this election. We thank poll workers, the essential workers for our democracy. And, we reaffirm that election outcomes are determined by the will of voters.</SectionP>
          <SectionP>As our country carries out this historic election in the midst of a pandemic, we are confident that America will meet this moment and continue to serve as a beacon of freedom for the world.</SectionP>
          <ButtonLink mode="dark" to="/100commitments" larger>View 100% Commitments</ButtonLink>
          <br />
          <br />
          <ButtonLink mode="dark" to="/votingaccess" larger>Joint Statement on Protecting Voting Access</ButtonLink>
          <br />
          <br />
          <ButtonLink mode="dark" to="/transition" larger>Statement of Peaceful Transition of Power</ButtonLink>
          <Section mode="light" relaxedPadding>
            <SectionH2>Signatories</SectionH2>
            <ResponsiveGrid centered negativeMargin>
              <CommitmentsSection companies={data.allContentfulCivicAllianceCompany.nodes} />
            </ResponsiveGrid>
          </Section>
          <Section mode="light" relaxedPadding>
            <SectionH2>Partner network</SectionH2>
            <ResponsiveGrid centered negativeMargin>
              { data.allContentfulCivicAllianceCompany.nodes
                  .filter(company => company.commitments.includes("Partner Network"))
                  .map(company => 
                    <Grid.Column key={company.id} title={company.name}>
                      <LogoHiddenLabel>{company.name}</LogoHiddenLabel>
                      <PartnerLogo src={company.logo.file.url} alt={company.name} logosize={company.size}/>
                    </Grid.Column>
                  )
              }
            </ResponsiveGrid>
          </Section>
          <Section mode="light" small>
            <SectionH1 style={{ marginBottom: "1.5rem" }}>We invite you to join our Civic Alliance and commit to being 100% In for democracy.</SectionH1>
            <ButtonAnchor mode="dark" href="https://democracyworks.typeform.com/to/oVtbNNuM" larger>
              Sign Our Statement
            </ButtonAnchor>
          </Section>
        </Letter>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allContentfulCivicAllianceCompany(filter: {commitments: {in: ["100% In For Democracy", "Partner Network"]}, logo: {file: {fileName: {ne: null}}}}, sort: {fields: name}) {
      nodes {
        name
        id
        commitments
        commitmentLevel
        executiveName
        executiveTitle
        logoSize
        logo {
          file {
            fileName
            url
          }
          fluid(maxWidth: 140) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;